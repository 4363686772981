<template>
  <div>
    <ExamFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div :class="$style.minHieght80">
      <div v-if="!isLoading">
        <div v-if="!examsCounts">
          <NoRecordFound />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH
                v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
                v-show="
                  showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)
                "
                :key="head.id"
              >
                {{ head.dataTableName }}
              </TH>
              <TH v-permission="classLevelPermission">Section</TH>
              <TH>Actions</TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(exam, index) in examsList" :key="index">
              <TD v-show="showColumnInDataTable(filtersData, 'Exam Title', ['Exam Title'])">
                <span
                  class="cursor-pointer text-primary-purple-600"
                  @click="redirectToDetailsPage(exam.id)"
                >
                  {{ exam.title }}
                </span>
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Exam Type', ['Exam Type'])">
                {{ exam.exam_type && exam.exam_type.title }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Subjects', ['Subjects'])">
                <UiBadgeDisplay
                  :options="exam.subject_exam_schedules"
                  :nested-label="['subject', 'title']"
                />
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Published')">
                {{
                  exam.published_at ? GENERAL_CONSTANTS.PUBLISHED : GENERAL_CONSTANTS.NOT_PUBLISHED
                }}
              </TD>
              <TD v-permission="classLevelPermission">
                {{ exam.section && exam.section.title }}
              </TD>
              <TD>
                <TableAction
                  :action-list="actionList"
                  :idx="index"
                  :current-user="exam"
                  @action="typeAction"
                />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
      <div v-else class="mt-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="examsCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
        v-show="!isLoading"
        :key="`ForceRender${forceRender}`"
        :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
        :max-range="paginationCounts(examsCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
        @filterRecord="filterRecord"
      />
      <ExamModal
        v-if="isModalShow"
        :modal="isModalShow"
        :edit="currentSelectedExam"
        @toggle="toggleExamModal"
      />
      <!-- DELETE EXAM MODEL -->
      <UIConfirmationModal
        v-if="deleteModelShow"
        :modal="deleteModelShow"
        heading="DELETE_EXAM"
        message="Are you sure you want to delete "
        button-text="Delete"
        :name="currentSelectedExam.title"
        remaining-message="exam ?"
        :is-loading="isDeleteInProgress"
        @cancel="toogleDeleteModel"
        @confirm="deleteExamById"
      />
      <UIConfirmationModal
        v-if="isPublishedConfirmation"
        :modal="isPublishedConfirmation"
        heading="PUBLISHED_EXAM"
        message="Are you sure you want to published "
        button-text="Published"
        :is-loading="isExamPublishedLoading"
        :name="currentSelectedExam.title"
        remaining-message="exam ?"
        @cancel="togglePublishedExamModal"
        @confirm="publishedExamHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import ExamFilter from '@src/router/views/exam-planner/exams/ExamViewFilter.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import ExamModal from '@views/admin/modals/AddAndEditExamModal.vue'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import examData from '@src/router/views/exam-planner/exams/examData.json'
import fileMixin from '@src/mixins/file-mixins'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TableAction,
    Pagination,
    Loader,
    ExamFilter,
    ExamModal,
    NoRecordFound,
    UiBadgeDisplay,
    UIConfirmationModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [fileMixin, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      filtersData: {},
      SCOPE_LEVELS,
      isLoading: false,
      isExamPublishedLoading: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }, { name: 'PUBLISH' }],
      exams: 'exams',
      forceRender: 0,
      deleteModelShow: false,
      currentSelectedExam: null,
      defaultSelectedColumns: examData.defaultSelectedColumns,
      tableHeaders: examData.tableHeaders,
      skipForFilterColumns: examData.skipForFilterColumns,
      isModalShow: false,
      isPublishedConfirmation: false,
      isDeleteInProgress: false,
      classLevelPermission: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL],
      },
    }
  },

  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope', 'activeRole']),
    ...mapState({
      showUiModal: (state) => state.layout.showUiModal,
      examsList: (state) => state.exams.examsList?.records || [],
      examsCounts: (state) =>
        state.exams.examsList?.meta?.total_records || GENERAL_CONSTANTS.EMPTY_RECORD,
    }),
  },
  watch: {
    currentClassScope: {
      handler(val) {
        if (val) this.filterRecord()
      },
      deep: true,
    },
    currentSectionScope: {
      handler(val) {
        this.filterRecord()
      },
      deep: true,
    },
  },
  mounted() {
    this.filterRecord()
  },
  created() {
    this.setRightbarData()
  },
  methods: {
    showColumnInDataTable,
    paginationCounts,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    setRightbarData() {
      const rightBarContent = {
        header: {
          title: 'Exam',
          buttons: [
            {
              title: 'Add New Exam',
              classes: ['primary-button-right'],
              action: {
                name: 'layout/setShowUiModal',
                payload: {},
                func: () => {
                  this.toggleExamModal()
                },
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'TOTAL_EX', value: this.examsCounts }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    redirectToDetailsPage(id) {
      this.$router?.push({
        name: 'exams-detail-view',
        params: {
          id: id,
        },
      })
    },
    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
      if (!this.deleteModelShow) this.currentSelectedExam = null
    },
    toggleExamModal(payload) {
      this.isModalShow = !this.isModalShow
      if (!this.isModalShow) this.currentSelectedExam = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    deleteExamById() {
      this.deleteType(this.currentSelectedExam.id)
    },
    filterRecord(range) {
      if (!this.currentClassScope) return

      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getExamsList(payload)
        .then(() => {
          this.setRightbarData()
        })
        .finally((err) => {
          this.isLoading = false
        })
    },
    typeAction(action, currentExam, idx) {
      this.currentSelectedExam = objectDeepCopy(currentExam)
      switch (action) {
        case 'Edit':
          this.toggleExamModal()
          break
        case 'Delete':
          this.toogleDeleteModel()
          break
        case 'PUBLISH':
          this.togglePublishedExamModal()
          break
      }
    },
    async deleteType(id) {
      this.isDeleteInProgress = true
      const [res, err] = await this.deleteExam(id)
      this.isDeleteInProgress = false
      if (res) {
        this.filterRecord()
        this.toogleDeleteModel()
      }
    },
    togglePublishedExamModal() {
      this.isPublishedConfirmation = !this.isPublishedConfirmation
      if (!this.isPublishedConfirmation) this.currentSelectedExam = null
    },
    async publishedExamHandler() {
      this.isExamPublishedLoading = true
      let id = this.currentSelectedExam.id
      let [response, error] = await this.publishedExamSchedule(id)
      this.isExamPublishedLoading = false
      if (response) {
        let message = 'Exam Successfully Published'
        this.$store.commit('toast/NEW', { message: message, type: 'success' }, { root: true })
        this.filterRecord()
        this.togglePublishedExamModal()
      }
    },
    ...mapActions('layout', ['setRightbarContent', 'setShowModal', 'setShowUiModal']),
    ...mapActions('exams', [
      'createExam',
      'getExamsList',
      'getExamTypes',
      'updateExam',
      'deleteExam',
      'getDataForAddEditExam',
      'publishedExamSchedule',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
