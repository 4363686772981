<template>
  <ValidationObserver @submit="addNewExam">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>
          {{ modalHeading }}
        </span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper v-if="!currentSectionScope && !edit">
            <UiSingleSelect
              v-model="selectedSection"
              title="Section"
              :options="sectionList"
              label="title"
              reduce="id"
              class="flex-1"
              rules="required"
              @change="loadExamData"
              @deSelect="loadExamData"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputBox
              v-model="examData.title"
              type="text"
              name="TITLE"
              title="TITLE"
              label="TITLE"
              placeholder="TITLE"
              class="flex-1"
              rules="required|alpha_numeric_spaces"
            />
            <InputBox
              v-model="examData.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              class="flex-1"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiSingleSelect
              v-model="examData.exam_type_id"
              title="EXAM_TYPE"
              rules="required"
              :label="'title'"
              :reduce="'id'"
              :options="examtypes"
              class="flex-1"
            />
          </InputFieldWrapper>
        </div>
        <div v-if="!edit">
          <Divider />
          <div class="flex justify-end mb-5 mx-5">
            <UIButton class="px-5" @click="addNewSubjectItem()">Add New Subject</UIButton>
          </div>

          <div
            v-for="(examSubject, index) in examSubjectsData"
            :key="index"
            class="border border-primary-purple-100 p-5 rounded-lg relative mb-5"
          >
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="examSubject.subject_id"
                title="Subject"
                :name="`SUBJECT + ${index}`"
                :dynamic-title="true"
                rules="required"
                label="title"
                reduce="id"
                :options="subjectsList"
                class="flex-1"
              />
              <InputBox
                v-model.number="examSubject.exam_duration"
                type="text"
                :name="`EXAM DURATION + ${index}`"
                :is-not-translated="true"
                title="Exam Duration(minutes)"
                label="Exam Duration"
                placeholder="Exam Duration"
                class="flex-1"
                :rules="`required|numeric|min_value:${EXAM_CONSTANTS.MIN_EXAM_TIME_DURATION}`"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiDateTimePicker
                v-model="examSubject.planned_at"
                title="Exam Date Time"
                :name="`Exam Date Time + ${index}`"
                class="flex-1"
                rules="required"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="examSubject.invigilator_id"
                :name="`INVIGILATOR + ${index}`"
                :is-not-translated="true"
                title="Invigilator"
                label="full_name"
                reduce="id"
                :options="invigilatorsList"
                class="flex-1"
                :search-through-api="true"
                @search="getInvigilatorList"
              />
              <UiSingleSelect
                v-model="examSubject.room_id"
                title="ROOM"
                :name="`ROOM + ${index}`"
                :is-not-translated="true"
                label="title"
                reduce="id"
                :options="roomsList"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <InputBox
                v-model="examSubject.total_marks"
                type="text"
                :name="`TOTAL_MARKS + ${index}`"
                :is-not-translated="true"
                title="Total Marks"
                label="Total Marks"
                placeholder="Total Marks"
                class="flex-1"
                rules="required|double"
              />
            </InputFieldWrapper>
            <span v-if="index" class="absolute top-3 right-3" @click="removeExamSubjectItem(index)">
              <icon
                class="icon cursor-pointer"
                width="12"
                height="12"
                color="primary-purple-600"
                icon="cross"
              />
            </span>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ primaryButtonText }}
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Divider from '@src/components/Divider.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import icon from '@components/icons/icon.vue'
import UiDateTimePicker from '@src/components/UiElements/UIDateTimePicker.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import EXAM_CONSTANTS from '@src/constants/exam-constants.js'

export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    UiSingleSelect,
    Divider,
    UIButton,
    icon,
    UiDateTimePicker,
    InputFieldWrapper,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      roomsList: [],
      EXAM_CONSTANTS,
      examData: {
        title: '',
        description: '',
        exam_type_id: '',
      },
      subjectsList: [],
      isLoading: false,
      examtypes: [],
      examSubjectitem: {
        planned_at: '',
        exam_duration: '',
        description: '',
        status: '',
        subject_id: '',
        total_marks: '',
        room_id: '',
      },
      examSubjectsData: [],
      invigilatorsList: [],
      selectedSection: null,
    }
  },
  computed: {
    primaryButtonText() {
      return this.edit ? 'Update' : 'Save'
    },

    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      sectionList: (state) => state.section.sectionList,
    }),
    modalHeading() {
      return !!this.edit ? 'Edit Exam Schedule' : 'Add Exam Schedule'
    },
  },
  watch: {
    currentClassScope: {
      handler(val) {
        if (val) this.loadExamData()
      },
    },
    currentSectionScope: {
      handler() {
        this.loadExamData()
      },
    },
  },
  created() {
    this.loadExamData()
    this.getInvigilatorList()
    this.getRoomsList()
    this.filterSectionsList()
    if (this.edit) this.setFormData()
  },

  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async getInvigilatorList(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      const res = await this.getTeacherWithFilters(payload)
      this.invigilatorsList = res?.data?.records || []
    },
    addNewSubjectItem() {
      let item = objectDeepCopy(this.examSubjectitem)
      this.examSubjectsData.push(item)
    },
    removeExamSubjectItem(idx) {
      this.examSubjectsData.splice(idx, 1)
    },
    async loadExamData() {
      this.examSubjectsData = []
      this.examSubjectsData = [...this.examSubjectsData, { ...this.examSubjectitem }]
      let subjectPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection),
        },
      }
      let examPayload = {}
      let payload = { subjectPayload: subjectPayload, examPayload: examPayload }
      const [response, error] = await this.getSubjectAndExamTypes(payload)
      if (!error) {
        this.examtypes = response[0].data.records
        this.subjectsList = response[1].data.records
      }
    },
    getRoomsList(range) {
      this.getRooms().then((response) => {
        this.roomsList = response?.records || []
      })
    },
    addNewExam() {
      this.isLoading = true
      let payload = { exam: this.getPayload() }
      if (this.edit) payload.id = this.edit?.id
      this.createExam(payload)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPayload() {
      let payload = {
        title: this.examData.title,
        description: this.examData.description,
        exam_type_id: this.examData.exam_type_id,
        subject_exam_schedules: this.examSubjectsData,
      }
      if (this.selectedSection) payload.section_id = this.selectedSection
      return payload
    },
    setFormData() {
      this.examData.title = this.edit?.title || ''
      this.examData.description = this.edit?.description || ''
      this.examData.exam_type_id = this.edit?.exam_type_id || ''
    },
    ...mapActions('exams', ['createExam', 'getSubjectAndExamTypes']),
    ...mapActions('room', ['getRooms']),
    ...mapActions('section', ['filterSectionsList']),
    ...mapActions('teacher', ['getTeacherStats', 'getTeacherWithFilters']),
  },
}
</script>
