<template>
  <TitleWrapper
    :filter-option="true"
    title="EXAMS"
    tooltip-title="EXAMS"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model.trim="filtersPayload.title"
          class="flex-1"
          title="EXAM_TITLE"
          type="text"
          placeholder="TITLE"
          :filter="FILTERS_LIST.TITLE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.exam_type_id"
          title="EXAM_TYPE"
          :label="'title'"
          :reduce="'id'"
          :options="examTypes"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.EXAM_TYPE"
          :search-through-api="true"
          @search="loadExamData"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.is_published"
          title="PUBLISHED"
          :options="RESULT_PUBLISHED_LIST"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.PUBLISHED"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.subject_id"
          title="Select Subject"
          :options="subjectList"
          label="title"
          placeholder="Select Subject"
          class="flex-1"
          :class="$style.widthItems"
          reduce="id"
          :filter="FILTERS_LIST.SUBJECT"
          :search-through-api="true"
          @emitQuery="updateQuery"
          @search="getSubjectList"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { getCountFromObject } from '@utils/generalUtil'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { mapActions, mapState } from 'vuex'
import { RESULT_PUBLISHED_LIST } from '@src/constants/general-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiInput,
    UiSingleSelect,
    InputFieldWrapper,
    TitleWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTER_KEYS,
      RESULT_PUBLISHED_LIST,
      subjectList: [],
      $where: {},
      examTypes: [],
      FILTERS_LIST,
      filtersPayload: {
        title: '',
        subject_id: '',
        exam_type_id: '',
        is_published: '',
        displayedColumns: [],
      },
      filtersCount: 0,
    }
  },

  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getSubjectList()
        this.loadExamData()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getSubjectList()
    this.loadExamData()
  },
  methods: {
    async loadExamData(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      const [response, error] = await this.getExamTypes(payload)
      if (!error) {
        this.examTypes = response?.data?.records
      }
    },
    getSubjectList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((resp) => {
        this.subjectList = resp?.data?.records || []
      })
    },
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('exams', ['getExamTypes']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
